import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"

import SEO from "@components/seo"
import {
  LazyLoadImage as img,
  LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

// svg decorations
// import "@components/svg-decorations/svg-decorations.scss"
// import WaveTop from "@components/svg-decorations/waveTop"
import WaveBottomDark from "../components/svg-decorations/waveBottomDark"
// import TopBottomCurve from "@components/svg-decorations/topBottomCurve"
// import CornerAccent from "@components/svg-decorations/cornerAccent"

//animation
// import { Zoom } from "react-reveal"
// import { Parallax } from "react-parallax"
// import Parallaxs from "parallax-js"

// Newly added Imports

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"

import linkedInBook from "../images/linked-in-book.png"

import keysToLinkedin from "../images/keys-to-linkedin.svg"
import fillAudienceImg from "../images/fill-your-audience.svg"
import salesMistakes from "../images/huge-mistakes-sales.svg"
import convertingSecret from "../images/sercret-to-converting.svg"

// import profileContentBg from "../images/faq-body-bg.svg"

import profileDownloadBg from "../images/profile-download-bg.png"

import oval1 from "@images/oval.svg"
import oval2 from "@images/oval-2.svg"
import oval3 from "@images/oval-3.svg"

// End of new imports

// const caseStudySwiperData = [
//   {
//     clientName: "Steven Pilkington",
//     clientPhoto: require("@images/steven-headshot.png"),
//     clientCompany: "Path | Home",
//     clientLogo: require("@images/steven-logo.png"),
//     chart: require("@images/steven-chart.png"),
//     leads: "0 to 1380 leads",
//     replies: "166 replies",
//     timeframe: "2 months",
//   },
//   {
//     clientName: "Ryan Estes",
//     clientPhoto: require("@images/ryan-headshot.png"),
//     clientCompany: "KitCaster",
//     clientLogo: require("@images/ryan-logo-white.png"),
//     chart: require("@images/ryan-chart.png"),
//     leads: "0 to 2641 leads",
//     replies: "950 replies",
//     timeframe: "6 months",
//   },
//   {
//     clientName: "Alex Dvorak",
//     clientPhoto: require("@images/alex-headshot.png"),
//     clientCompany: "Transworld",
//     clientLogo: require("@images/alex-logo-white.png"),
//     chart: require("@images/alex-chart.png"),
//     leads: "0 to 1310 leads",
//     replies: "190 replies",
//     timeframe: "63 days",
//   },
// ]

// Inline Styling

const heroWrapper = {
  overflow: "hidden",
  paddingTop: "6vw",
}
const profileWrapper = {
  marginBottom: "3vw",
}
const profileHeader = {
  fontSize: 35,
}
const profileDescription = {
  fontSize: 12,
}

const profileBookImg = {
  maxWidth: "100%",
}

const CardBox = {
  boxShadow: "0px 7px 8px #00000029",
  borderRadius: "20px",
  border: "1px solid #ECECEC",
}

const learningWrapper = {
  padding: "10rem 0 10rem 0",
  position: "relative",
  overflow: "hidden",
  // backgroundImage: `url(${profileContentBg})`,
  // backgroundRepeat: "no-repeat",
  // backgroundSize: "cover",
  // backgroundPosition: "50% 70%",
  // backgroundAttachment: "local",
  // padding: "5rem 0",
}
const learningHeader = {
  color: "#5526FD",
  fontSize: 30,
  letterSpacing: "1.16px",
}

const learningDescription = {
  color: "#000000",
  fontSize: 14,
  letterSpacing: "0.55px",
}

const contentTitle = {
  color: "#5431FD",
  fontSize: 17,
  fontWeight: "bold",
  letterSpacing: "0.99",
}

const contentDescription = {
  color: "#000",
  fontSize: 15,
  fontWeight: "normal",
  letterSpacing: "0.55px",
}

const label = {
  color: "#5F1469",
  fontSize: 14,
  fontWeight: "bold",
  textAlign: "left",
}

const input = {
  border: "1px solid #C1C1C1",
  borderRadius: 7,
  padding: "1.5rem 1rem",
}

const profileDownload = {
  backgroundColor: "#000",
  padding: "10rem 0",
  backgroundImage: `url(${profileDownloadBg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
}

const profiledDownloadTitle = {
  fontSize: 30,
  fontWeight: "bold",
  color: "#fff",
}

const profiledDownloadDescription = {
  color: "#fff",
  fontSize: 15,
}

// const ulStyle = {
//   position: "relative",
// }

// End of Styling

export const ProfileGuide = ({ location }) => (
  <>
    <Layout location={location}>
      <SEO
        title="How To Optimize Your LinkedIn Profile For Sales (PDF)"
        description="This free PDF guide outlines the essential steps necessary to optimize your LinkedIn profile for sales. Your profile IS your sales pitch, and a poorly designed profile can kill your sales (and not for the reasons you might think)... Free Guide. LinkedIn Profile Optimization. LinkedIn Lead Gen."
      />

      <div className="hero" style={heroWrapper} id="hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-self-end profile-box">
              <div
                className="text-center profile-text-wrapper"
                style={profileWrapper}
              >
                <h1
                  className="display-4 font-weight-bold"
                  style={profileHeader}
                >
                  The Sales Professional's Guide To The Perfect LinkedIn Profile
                </h1>
                <p className="lead pt-4" style={profileDescription}>
                  This PDF guide outlines the 9 crucial steps every sales
                  professional needs to take in order to optimize their LinkedIn
                  profile for sales. Follow the simple checklist in this guide
                  and stop getting ignored by high-caliber leads on LinkedIn.
                </p>
                <div className="mt-5">
                  <Card style={CardBox}>
                    <CardBody style={{ padding: "2rem 4rem" }}>
                      <Form>
                        <FormGroup className="text-left">
                          <Label for="First Name" style={label}>
                            First name*
                          </Label>
                          <Input
                            style={input}
                            type="email"
                            name="email"
                            id="exampleEmail"
                            placeholder="Type your first name"
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label for="email" style={label}>
                            Email*
                          </Label>
                          <Input
                            style={input}
                            type="email"
                            name="email"
                            id="exampleEmail"
                            placeholder="Type your email"
                          />
                        </FormGroup>
                        <Link to="/" className="btn btn-primary btn-lg mt-4">
                          Yes! Send me the pdf guide
                        </Link>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <LazyLoadComponent>
                <img
                  src={linkedInBook}
                  alt="linked in book"
                  style={profileBookImg}
                />
              </LazyLoadComponent>
            </div>
          </div>
        </div>
        <div className="hero-bg-image" />
        <ul className="list-unstyled moving-bg" id="scenes">
          <li className="scene" data-depth="0.2">
            <img src={require("@images/bg1.svg")} alt="bg1" />
          </li>
          <li className="scene" data-depth="0.6">
            <img src={require("@images/bg2.svg")} alt="bg2" />
          </li>
          <li className="scene" data-depth="1">
            <img src={require("@images/bg3.svg")} alt="bg3" />
          </li>
        </ul>
        <WaveBottomDark />
      </div>
      <div className="learning-content" style={learningWrapper}>
        <img className="oval-svg" src={oval1} alt="Oval svg" />
        <img className="oval-2" src={oval2} alt="Oval svg" />
        <img className="oval-3" src={oval3} alt="Oval svg" />
        <Container>
          <Row>
            <Col>
              <div className="text-center mb-5">
                <h3 style={learningHeader}>What You'll Learn</h3>
                <p style={learningDescription}>
                  We've generated hundreds of thousands of leads on LinkedIn,
                  and we know what it takes to win...
                </p>
              </div>
              <Card style={CardBox}>
                <CardBody style={{ padding: "4rem" }}>
                  <Container>
                    <Row className="mb-5">
                      <Col lg={2} className="d-flex justify-content-center">
                        <img src={keysToLinkedin} alt="keys to linked in" />
                      </Col>
                      <Col lg={10}>
                        <h3 style={contentTitle}>
                          The 9 Keys To A LinkedIn Profile That Converts
                        </h3>
                        <p className="m-0" style={contentDescription}>
                          Why most profiles are failing to capture lead
                          attention, and the 9 simple changes necessary to build
                          ironclad trust with your prospects, and convert more
                          deals.
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col lg={2} className="d-flex justify-content-center">
                        <img src={fillAudienceImg} alt="Dream Prospects" />
                      </Col>
                      <Col lg={10}>
                        <h3 style={contentTitle}>
                          How To Fill Your Audience With Dream Prospects
                        </h3>
                        <p className="m-0" style={contentDescription}>
                          Your profile is just one piece of the puzzle... We'll
                          share the 5 tactics needed to generate and convert a
                          loyal tribe of dream prospects -- on auto-pilot. build
                          ironclad trust with your prospects, and convert more
                          deals.
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col lg={2} className="d-flex justify-content-center">
                        <img src={salesMistakes} alt="Huge Mistakes" />
                      </Col>
                      <Col lg={10}>
                        <div>
                          <h3 style={contentTitle}>
                            3 Huge Mistakes That Might Be Killing Your Sales
                          </h3>
                          <p className="m-0" style={contentDescription}>
                            Sales don't happen by accident. If you've ever
                            wondered why your LinkedIn leads aren't converting
                            -- these three common mistakes might be the reason.
                            build ironclad trust with your prospects, and
                            convert more deals.
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2} className="d-flex justify-content-center">
                        <img src={convertingSecret} alt="Converting Secret" />
                      </Col>
                      <Col lg={10}>
                        <div>
                          <h3 style={contentTitle}>
                            The Secret To Converting Leads Over The Long-Term
                          </h3>
                          <p className="m-0" style={contentDescription}>
                            We'll show you how to build trust with each lead
                            over time to become (1) an influencer in your niche,
                            and (2) a subject matter expert in the mind of your
                            prospects
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="profile-download" style={profileDownload}>
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h3 style={profiledDownloadTitle} className="mb-3">
                Start Converting More Leads
              </h3>
              <p style={profiledDownloadDescription} className="mb-5">
                Your profile is your sales pitch. It's time to optimize it for
                conversions.
              </p>
              <Link
                to="#hero"
                type="button"
                className="btn btn-primary btn-lg btn-red"
              >
                DOWNLOAD YOUR FREE GUIDE (PDF)
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  </>
)

export default ProfileGuide
